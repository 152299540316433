import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{staticClass:"pa-5 transparent",attrs:{"flat":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":_vm.breaking ? 12 : 6}},[_c(VRow,{staticClass:"ma-auto"},[_c(VSpacer),_c(VTextField,{attrs:{"label":"Lösung","outlined":""},model:{value:(_vm.solutionInput),callback:function ($$v) {_vm.solutionInput=$$v},expression:"solutionInput"}}),_c(VSpacer)],1)],1),_c(VCol,{attrs:{"cols":_vm.breaking ? 12 : 6}},[_c('div',[_c('audio',{attrs:{"controls":""}},[_c('source',{attrs:{"src":require("../assets/Puzzle4.mp3"),"type":"audio/mp3"}})]),_c(VSpacer),_c('audio',{attrs:{"controls":""}},[_c('source',{attrs:{"src":require("../assets/Puzzle4Flipped.mp3"),"type":"audio/mp3"}})])],1)])],1)],1),(_vm.solutionInput === _vm.solution)?_c('div',[_c(VDivider,{staticClass:"ma-5"}),_c(VBtn,{attrs:{"color":"success","width":"330","height":"100","outlined":""},on:{"click":function($event){return _vm.finish()}}},[_c('h2',[_vm._v("weiter")]),_c(VIcon,{staticClass:"ml-3"},[_vm._v("mdi-chevron-right")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }