import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('div',{staticClass:"ma-5"}),_c(VCard,{staticClass:"justify-center text-center bg transparent",attrs:{"outlined":""}},[_c(VCardTitle,{staticClass:"mb-5"},[_c('h2',[_vm._v("Rätsel 3: Erinnerungen")])]),_c(VRow,{staticClass:"ma-0"},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":_vm.breaking ? 12 : 6}},[_c(VCard,{staticClass:"box",attrs:{"outlined":""}},[_c('div',{staticClass:"puzzle"},[_c('div',{staticClass:"grid-container"},_vm._l((_vm.itemCount),function(item,index){return _c('div',{staticClass:"grid-item",style:(_vm.getOpacity(index))},[_c('input',{staticStyle:{"width":"100%","height":"100%","font-size":"20px","text-align":"center"},attrs:{"id":index},on:{"input":function($event){return _vm.changeItemState(index)}}})])}),0)])])],1),(_vm.solved)?_c(VCol,{staticStyle:{"margin":"auto"},attrs:{"cols":_vm.breaking ? 12 : 6}},[(_vm.breaking)?_c(VDivider,{staticClass:"ma-5"}):_vm._e(),_c(VBtn,{attrs:{"color":"success","width":"330","height":"100","outlined":""},on:{"click":function($event){return _vm.nextPuzzle()}}},[_c('h2',[_vm._v("nächstes Rätsel")]),_c(VIcon,{staticClass:"ml-3"},[_vm._v("mdi-chevron-right")])],1)],1):_vm._e(),(!_vm.solved)?_c(VCol,{attrs:{"cols":_vm.breaking ? 12 : 4}},[(_vm.breaking)?_c(VDivider,{staticClass:"ma-5"}):_vm._e(),_c('p',{staticClass:"text-left"},[_vm._v("1. Ein Ort an dem keine Rutsche zu hoch ist.")]),_c('p',{staticClass:"text-left"},[_vm._v("2. Das legendärste Hotel auf der Welt")]),_c('p',{staticClass:"text-left"},[_vm._v("3. Die große Wikinger-Reise.")]),_c('p',{staticClass:"text-left"},[_vm._v("4. Der Pirat macht ganz schön leckere Souvlaki Spieße.")]),_c('p',{staticClass:"text-left"},[_vm._v("5. So billig? Da kauf ich gleich zwei Sets.")]),_c('p',{staticClass:"text-left"},[_vm._v("6. Ein Land mit Stränden voller netter Aufräumarbeiter.")])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }