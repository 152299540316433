<template>
  <div>
    <video width="520" height="340" controls>
      <source src="../assets/FinalVideo.mp4" type="video/mp4">
    </video>
  </div>
</template>

<script>
export default {
  name: 'FinalVideoView',
  data: () => ({
    //
  }),
};

</script>