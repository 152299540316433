<template>
  <div>
    <v-card class="pa-5 transparent" flat>
      <v-row>
        <v-col :cols="breaking ? 12 : 6">
          <v-row class="ma-auto">
            <v-spacer></v-spacer>
            <v-text-field
                v-model="solutionInput"
                label="Lösung"
                outlined
            ></v-text-field>
            <v-spacer></v-spacer>
          </v-row>
        </v-col>
        <v-col :cols="breaking ? 12 : 6">
          <div>
            <audio controls>
              <source src="../assets/Puzzle4.mp3" type="audio/mp3">
            </audio>
            <v-spacer></v-spacer>
            <audio controls>
              <source src="../assets/Puzzle4Flipped.mp3" type="audio/mp3">
            </audio>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <div v-if="solutionInput === solution">
      <v-divider class="ma-5"></v-divider>
      <v-btn
          color="success"
          width="330"
          height="100"
          outlined
          @click="finish()"
      >
        <h2>weiter</h2>
        <v-icon class="ml-3">mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FourthPuzzleView',
  components: {
    VuetifyAudio: () => import('vuetify-audio')
  },
  data: () => ({
    solution: 'fR0h3 We1n4cHteN!',
    solutionInput: null
  }),
  methods: {
    finish() {
      this.$globalState.globaleVariable = true
      setTimeout(() => {
        this.$globalState.globaleVariable = false
      }, 3000)
      this.$router.push('/3583936596')
    }
  },
  computed: {
    breaking() {
      return this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm'
    },
  }
}
</script>