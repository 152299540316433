<template>
  <div>
    <div class="center">
      <v-btn
          color="primary"
          outlined
          @click="$router.push('/1014862880')"
      >
        rein in das Abenteuer
      </v-btn>
    </div>
    <div class="footer"></div>
  </div>
</template>

<script>
  export default {
    name: 'Home',

    components: {

    },
  }
</script>

<style scoped>
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 200px;
  text-align: center;
  background-image: url("../assets/Bush.gif");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
</style>