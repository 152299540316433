<template>
  <div class="text-center">
    <div class="ma-5"></div>
    <v-card outlined class="justify-center text-center bg transparent">
      <v-card-title class="mb-5">
        <h2>Rätsel 3: Erinnerungen</h2>
      </v-card-title>
      <v-row class="ma-0">
        <v-col :cols="breaking ? 12 : 6" class="pa-0">
          <v-card class="box" outlined>
            <div class="puzzle">
              <div class="grid-container">
                <div
                    class="grid-item"
                    v-for="(item, index) in itemCount"
                    :style="getOpacity(index)"
                >
                  <input
                      style="width: 100%; height: 100%; font-size: 20px; text-align: center"
                      :id="index"
                      @input="changeItemState(index)"
                  />
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col :cols="breaking ? 12 : 6" v-if="solved" style="margin: auto">
          <v-divider class="ma-5" v-if="breaking"></v-divider>
          <v-btn
              color="success"
              width="330"
              height="100"
              outlined
              @click="nextPuzzle()"
          >
            <h2>nächstes Rätsel</h2>
            <v-icon class="ml-3">mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
        <v-col :cols="breaking ? 12 : 4" v-if="!solved">
          <v-divider class="ma-5" v-if="breaking"></v-divider>
          <p class="text-left">1. Ein Ort an dem keine Rutsche zu hoch ist.</p>
          <p class="text-left">2. Das legendärste Hotel auf der Welt</p>
          <p class="text-left">3. Die große Wikinger-Reise.</p>
          <p class="text-left">4. Der Pirat macht ganz schön leckere Souvlaki Spieße.</p>
          <p class="text-left">5. So billig? Da kauf ich gleich zwei Sets.</p>
          <p class="text-left">6. Ein Land mit Stränden voller netter Aufräumarbeiter.</p>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ThirdPuzzleView',
  components: {

  },
  data: () => ({
    itemCount: 144,
    solved: false,
    clickableItems: [
      [0, "S"], [9, "C"], [18, "H"], [27, "L"], [36, "O"], [45, "S"], [54, "S"], [72, "D"], [81, "A"], [90, "N"], [99, "K"], [108, "E"], [117, "R"], [126, "N"],
      [42, "E"], [51, "S"], [60, "P"], [69, "E"], [78, "R"], [87, "A"], [96, "N"], [105, "Z"], [114, "A"],
      [11, "H"], [20, "E"], [29, "L"], [38, "G"], [47, "O"], [56, "L"], [65, "A"], [74, "N"], [83, "D"],
      [110, "K"], [111, "R"], [112, "E"], [113, "T"],
      [127, "A"], [128, "G"], [129, "E"], [130, "L"],
      [73, "Ä"], [75, "E"], [76, "M"], [77, "A"], [79, "K"]
    ],
  }),
  methods: {
    isClickableItem(index) {
      return this.clickableItems.some(a => a[0] === index)
    },
    getOpacity(index) {
      const clickable = this.isClickableItem(index);
      return {
        opacity: clickable ? 1 : 0,
        "pointer-events": clickable ? 'auto' : 'none'
      };
    },
    changeItemState(index) {
      const elem = document.getElementById(index)

      // prevent more than one character
      elem.value = elem.value[0]

      // set character to uppercase
      if(elem.value !== 'ß')
        elem.value = elem.value.toUpperCase()

      // only allow A-Z, ÄÖÜ, ß
      if (!elem.value.match(/^[A-ZÄÖÜß]$/))
        elem.value = ''

      // check if item is correct
      for (let i = 0; i < this.clickableItems.length; i++) {
        if(this.clickableItems[i][0] === index) {
          this.clickableItems[i][2] = elem.value === this.clickableItems[i][1]
          break
        }
      }

      // check if all items are correct
      this.solved = this.clickableItems.every(e => e[2])
    },
    nextPuzzle() {
      this.$globalState.globaleVariable = true
      setTimeout(() => {
        this.$globalState.globaleVariable = false
      }, 3000)
      this.$router.push('/4217277742')
    }
  },
  computed: {
    breaking() {
      return this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm'
    },
  }
}
</script>

<style scoped>
.bg {
  background-image: url('../assets/BG1.png');
  background-size: cover;
}

.box {
  margin: auto;
  width: 50%;
  max-width: 330px;
  border: 1px solid gray;
  height: 585px;
}

.puzzle {
  background-image: url("../assets/Puzzle3.png");
  min-height: 585px;
}

.grid-container {
  display: grid;
  margin-left: 16px;
  padding-top: 16px;
  grid-template-columns: 35px 35px 35px 35px 35px 35px 35px 35px 35px;
  grid-template-rows: 35px 35px 35px 35px 35px 35px 35px 35px 35px;
  min-height: 574px;
}

.grid-item {
  font-size: 10px;
}

p {
  font-family: Calibri, serif;
}
</style>