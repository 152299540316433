import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ThirdPuzzleView from '../views/ThirdPuzzleView.vue'
import FourthPuzzleView from '../views/FourthPuzzleView.vue'
import FinalVideoView from '../views/FinalVideoView.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/1014862880',
    name: 'third_puzzle',
    component: ThirdPuzzleView,
  },
  {
    path: '/4217277742',
    name: 'fourth_puzzle',
    component: FourthPuzzleView
  },
  {
    path: '/3583936596',
    name: 'final_video',
    component: FinalVideoView
  }
]

const router = new VueRouter({
  routes
})

export default router
