<template>
  <v-app class="bg">
    <v-main class="text-center ma-5">
      <v-card class="pa-1 snowfall rounded-0">
        <h1
            style="font-size: 40pt; color: #0b141f; font-family: Roboto, serif; text-shadow: 1px 1px 2px aliceblue; cursor: pointer"
            @click="$router.push('/')"
        >Eine geheime Webseite</h1>
      </v-card>
      <router-view></router-view>
      <div v-if="$globalState.globaleVariable" class="successEffect"></div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style scoped>
.bg {
  background-image: url('../src/assets/SnowflakesBG.png');
  background-repeat: repeat-x;
  background-size: 30%;
}

.snowfall {
  background-image: url('../src/assets/Snowfall.gif'), linear-gradient(to bottom right, #66b9c0, #3dd2cd);
  background-repeat: repeat;
  background-size: auto;
}

.successEffect {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 999999;
  background-image: url('../src/assets/ConfettiShort.gif'), url('../src/assets/Sparkles.gif');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  animation: successEffect 2s ease-out;
}
</style>
